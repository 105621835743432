import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Grid,
  ThemeProvider,
  // Tooltip,
  Typography,
  useMediaQuery,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import theme from "../../../theme";
import { useTheme } from "@emotion/react";
import AdminInput from "../../../components/adminInput";
import AdminButton from "../../../components/adminButton";
import { Icon } from "@iconify/react";
import { addPromoCode } from "../../../store";
import { useDispatch } from "react-redux";
import { InputLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../components/spinner";
import moment from "moment";

const Index = () => {
  var checkDate = new Date().toISOString().split("T")[0];
  var newDate = moment(checkDate).format("YYYY-MM-DD");
  var mobiletheme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(mobiletheme.breakpoints.down("sm"));
  const [formData, setFormData] = useState({});
  const [formDataError, setFormDataError] = useState({});
  const [showLoader, setShowLoader] = useState(false);

  const [startDate, setStartDate] = useState("dd/mm/yyyy");
  const [endDate, setEndDate] = useState("dd/mm/yyyy");

  const [startDateForSend, setStartDateForSend] = useState("");
  const [endDateForSend, setEndDateForSend] = useState("");

  const handleStartDate = (value) => {
    const valDate = moment(value).format("YYYY-MM-DD");
    setStartDate(value);
    setStartDateForSend(valDate);
  };
  // const today = new Date().toISOString().split('T')[0];
  const handleEndDate = (value) => {
    const valDate = moment(value).format("YYYY-MM-DD");
    setEndDate(value === "" ? "dd/mm/yyyy" : value);
    setStartDate(
      valDate === "dd/mm/yyyy" || valDate === "Invalid date"
        ? "dd/mm/yyyy"
        : startDate
    );
    setEndDateForSend(valDate === "Invalid date" ? "" : valDate);
  };

  const navigate = useNavigate();
  console.log("formData?,.discountType", formData?.discountType);
  useEffect(() => {
    setFormData((formData) => ({
      ...formData,
      discountType: "2",
    }));
  }, []);

  const handleChange = (value) => {
    if (value) {
      setFormData((formData) => ({
        ...formData,
        discountType: value,
      }));
    }
    if (value === "2") {
      setFormData((formData) => ({
        ...formData,
        discountValue: "",
      }));
    } else {
      setFormData((formData) => ({
        ...formData,
        discountPercent: "",
      }));
    }
  };
  const today = moment().format("YYYY-MM-DD");
  const handleSend = () => {
    const validPercentage = new RegExp(
      /(^$)|(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)\.(\.[0-9]{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/
    );

    if (!formData?.title || formData.title.trim() === "") {
      return setFormDataError((formDataError) => ({
        ...formDataError,
        title: "Please enter title",
      }));
    }
    if (!formData?.promoCode || formData.title.trim() === "") {
      return setFormDataError((formDataError) => ({
        ...formDataError,
        promoCode: "Please enter promo code",
      }));
    }
    if (startDate == "dd/mm/yyyy") {
      setFormDataError((formDataError) => ({
        ...formDataError,
        startDate: "Please enter start date",
      }));
      return;
    }

    if (endDate == "dd/mm/yyyy") {
      setFormDataError((formDataError) => ({
        ...formDataError,
        endDate: "Please enter end date",
      }));
      return;
    }

    if (formData?.discountType === "1") {
      if (!formData?.discountPercent) {
        return setFormDataError((formDataError) => ({
          ...formDataError,
          discountPercent: "Please enter number",
        }));
      } else if (!validPercentage.test(formData?.discountPercent)) {
        return setFormDataError((formDataError) => ({
          ...formDataError,
          discountPercent: "Percentage should be less than 100",
        }));
      }
    } else {
      if (!formData?.discountValue) {
        return setFormDataError((formDataError) => ({
          ...formDataError,
          discountValue: "Please enter number",
        }));
      }
    }

    let data = {
      language: "en",
      title: formData?.title,
      code: formData?.promoCode,
      startDate: startDateForSend,
      expiredAt: endDateForSend,
      discountType: formData?.discountType,
      discountValue:
        formData?.discountType === "1"
          ? formData?.discountPercent
          : formData?.discountValue,
    };

    dispatch(addPromoCode(data, setShowLoader, navigate));
  };

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>QT PET - Promo Code </title>
      </Helmet>
      <Card>
        <Box sx={{ background: "white", mt: isMobile ? -2 : 0, py: 2, px: 5 }}>
          <Grid container sx={{ justifyContent: "space-between" }}>
            <Grid item sx={{ mt: 4.2 }}>
              <Typography variant="body1" sx={{ fontSize: 18 }}>
                <strong>Promo Code</strong>
              </Typography>
            </Grid>
            <Grid item>
              <AdminButton
                title="Back"
                onClick={() => navigate("/dashboard/promoCode")}
              />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ px: 5 }}>
          <Grid container spacing={2} sx={{ pb: 1.5 }}>
            <Grid item xl={6} sm={12}>
              <AdminInput
                title="Title"
                onChange={(e) => {
                  if (e) {
                    setFormData((formData) => ({
                      ...formData,
                      title: e.target.value,
                    }));
                    if (e) {
                      setFormDataError((formDataError) => ({
                        ...formDataError,
                        title: "",
                      }));
                    }
                  }
                }}
                formError={formDataError.title}
              />
            </Grid>
            <Grid item xl={6} sm={12}>
              <AdminInput
                title="Promo Code"
                maxLength={5}
                value={formData?.promoCode?.toUpperCase()}
                onChange={(e) => {
                  if (e) {
                    setFormData((formData) => ({
                      ...formData,
                      promoCode: e?.target?.value
                        ?.toUpperCase()
                        .replace(/[^\w\s]/gi, ""),
                    }));
                    if (e) {
                      setFormDataError((formDataError) => ({
                        ...formDataError,
                        promoCode: "",
                      }));
                    }
                  }
                }}
                formError={formDataError.promoCode}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ pb: 1.5 }}>
            <Grid item xs={isMobile ? 12 : 6}>

              <AdminInput
                title="Select start date"
                type={"date"}
                min={today}
                onKeyDown={(e) => e.preventDefault()}
                value={startDate || ""}
                onChange={(e) => handleStartDate(e.target.value)}
                formError={formDataError.startDate}
              />
            </Grid>

            <Grid item xs={isMobile ? 12 : 6}>
              <AdminInput
                title="Select end date"
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                min={endDate != "dd/mm/yyyy" ? endDate : newDate}
                value={endDate || ""}
                onChange={(e) => handleEndDate(e.target.value)}
                formError={formDataError.endDate}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ pb: 1.5 }}>
            <Grid item xs={isMobile ? 12 : 6}>
              <FormControl fullWidth>
                <InputLabel id="test-select-label">Discount Type</InputLabel>

                <Select
                  title="Select Discount Type"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={
                    formData?.discountType === undefined
                      ? "2"
                      : formData?.discountType
                  }
                  label="Discount Type"
                  onChange={(e) => handleChange(e.target.value)}
                >
                  <MenuItem value="2">Amount</MenuItem>
                  <MenuItem value="1">Percentage</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {formData?.discountType === "2" ? (
              <Grid item xs={isMobile ? 12 : 6}>
                <AdminInput
                  title="Amount"
                  maxLength={5}
                  value={formData?.discountValue}
                  onChange={(e) => {
                    const numeric = /^[0-9\b]+$/;

                    if (e.target.value === "" || numeric.test(e.target.value)) {
                      setFormData((formData) => ({
                        ...formData,
                        discountValue: e.target.value,
                      }));
                      if (e) {
                        setFormDataError((formDataError) => ({
                          ...formDataError,
                          discountValue: "",
                        }));
                      }
                    }
                  }}
                  formError={formDataError?.discountValue}
                />
              </Grid>
            ) : (
              <Grid item xs={isMobile ? 12 : 6}>
                <AdminInput
                  type="text"
                  endIcon={"%"}
                  title="Percentage"
                  value={formData?.discountPercent}
                  onChange={(e) => {
                    const value = e.target.value;
                    const numeric = /^[0-9\b]*$/;

                    // Check if the input is empty or matches the numeric pattern
                    if (value === "" || numeric.test(value)) {
                      // Convert the value to a number for comparison
                      const numericValue = Number(value);

                      // Validate the numeric value is between 0 and 100
                      if (numericValue >= 0 && numericValue <= 100) {
                        setFormData((formData) => ({
                          ...formData,
                          discountPercent: value,
                        }));
                        setFormDataError((formDataError) => ({
                          ...formDataError,
                          discountPercent: "",
                        }));
                      } else {
                        // Set an error if the value is out of range
                        setFormDataError((formDataError) => ({
                          ...formDataError,
                          discountPercent:
                            "Discount percent must be between 0 and 100.",
                        }));
                      }
                    } else {
                      // Set an error if the value is not numeric
                      setFormDataError((formDataError) => ({
                        ...formDataError,
                        discountPercent: "Please enter a valid number.",
                      }));
                    }
                  }}
                  formError={
                    formDataError?.discountPercent
                      ? formDataError?.discountPercent
                      : ""
                  }
                />
              </Grid>
            )}
            <Grid item xs={isMobile ? 12 : 6}>
              <AdminButton
                title="Save"
                startIcon={<Icon icon="material-symbols:save" />}
                onClick={handleSend}
              />
            </Grid>
          </Grid>
        </Box>
      </Card>
      {showLoader ? (
        <>
          <Box
            style={{
              position: "absolute",
              top: "69%",
              left: "50%",
            }}
          >
            <Spinner />
          </Box>
        </>
      ) : (
        ""
      )}
    </ThemeProvider>
  );
};
export default Index;
